import { useEffect, useRef } from "react";

export default function Scape() {
    const canvas = useRef();

    // Global Variables
    var DIRECTION = {
        IDLE: 0,
        UP: 1,
        DOWN: 2,
        LEFT: 3,
        RIGHT: 4
    };
    
    var rounds = [5, 5, 3, 3, 2];
    var colors = ['#1abc9c', '#2ecc71', '#3498db', '#8c52ff', '#9b59b6'];
    
    // The ball object (The cube that bounces back and forth)
    var Ball = {
        new: function (incrementedSpeed) {
            return {
                width: 18,
                height: 18,
                x: (canvas.current.width / 2) - 9,
                y: (canvas.current.height / 2) - 9,
                moveX: DIRECTION.LEFT,
                moveY: DIRECTION.DOWN,
                speed: incrementedSpeed || 7 
            };
        }
    };
    
    // The ai object (The two lines that move up and down)
    var Ai = {
        new: function (side) {
            return {
                width: 180,
                height: 36,
                x: (canvas.current.width / 2) - 90,
                y: canvas.current.height - 50,
                score: 0,
                move: DIRECTION.IDLE,
                speed: 8
            };
        }
    };

    var Game = {
        initialize: function () {
            // canvas.current = document.querySelector('canvas');
            // this.context = canvas.current.getContext('2d');
            this.canvas = canvas.current;
            this.context = canvas.current.getContext('2d');
            console.log(this.context);
    
            canvas.current.width = 650;
            canvas.current.height = 1200;
    
            canvas.current.style.width = (canvas.current.width / 2) + 'px';
            canvas.current.style.height = (canvas.current.height / 2) + 'px';
    
            this.player = Ai.new.call(this, 'left');
            this.ai = Ai.new.call(this, 'left');
            this.ball = Ball.new.call(this);
    
            this.ai.speed = 5;
            this.running = true;
            this.over = false;
            this.turn = this.ai;
            this.timer = this.round = 0;
            this.color = '#e4e4e4';
    
            Pong.draw();
            Pong.listen();
        },

        endGameMenu: function (text) {
            // Change the canvas font size and color
            Pong.context.font = '45px Courier New';
            Pong.context.fillStyle = this.color;
    
            // Draw the rectangle behind the 'Press any key to begin' text.
            Pong.context.fillRect(
                Pong.canvas.width / 2 - 350,
                Pong.canvas.height / 2 - 48,
                700,
                100
            );
    
            // Change the canvas color;
            Pong.context.fillStyle = '#ffffff';
    
            // Draw the end game menu text ('Game Over' and 'Winner')
            Pong.context.fillText(text,
                Pong.canvas.width / 2,
                Pong.canvas.height / 2 + 15
            );
    
            setTimeout(function () {
                Pong = Object.assign({}, Game);
                Pong.initialize();
            }, 3000);
        },
    
        // Update all objects (move the player, ai, ball, increment the score, etc.)
        update: function () {
            if (!this.over) {
                // If the ball collides with the bound limits - correct the x and y coords.
                if (this.ball.x <= 50) this.ball.moveX = DIRECTION.RIGHT;
                if (this.ball.x >= canvas.current.width - this.ball.width - 50) this.ball.moveX = DIRECTION.LEFT;
                // if (this.ball.y <= 0) this.ball.moveY = DIRECTION.DOWN;
                // if (this.ball.y >= canvas.current.height - this.ball.height) this.ball.moveY = DIRECTION.UP;
    
                // Move player if they player.move value was updated by a keyboard event
                if (this.player.move === DIRECTION.LEFT) this.player.x -= this.player.speed;
                else if (this.player.move === DIRECTION.RIGHT) this.player.x += this.player.speed;
    
                // On new serve (start of each turn) move the ball to the correct side
                // and randomize the direction to add some challenge.
                // if (Pong._turnDelayIsOver.call(this) && this.turn) {
                //     this.ball.moveX = this.turn === this.player ? DIRECTION.LEFT : DIRECTION.RIGHT;
                //     this.ball.moveY = [DIRECTION.UP, DIRECTION.DOWN][Math.round(Math.random())];
                //     this.ball.y = Math.floor(Math.random() * canvas.current.height - 200) + 200;
                //     this.turn = null;
                // }
    
                // If the player collides with the bound limits, update the x and y coords.
                if (this.player.y <= 0) this.player.y = 0;
                else if (this.player.y >= (canvas.current.height - this.player.height)) this.player.y = (canvas.current.height - this.player.height);
    
                // Move ball in intended direction based on moveY and moveX values
                if (this.ball.moveY === DIRECTION.UP) this.ball.y -= (this.ball.speed / 1.5);
                else if (this.ball.moveY === DIRECTION.DOWN) this.ball.y += (this.ball.speed / 1.5);
                if (this.ball.moveX === DIRECTION.LEFT) this.ball.x -= this.ball.speed;
                else if (this.ball.moveX === DIRECTION.RIGHT) this.ball.x += this.ball.speed;
    
                // Handle ai (AI) UP and DOWN movement
                // if (this.ai.y > this.ball.y - (this.ai.height / 2)) {
                //     if (this.ball.moveX === DIRECTION.RIGHT) this.ai.y -= this.ai.speed / 1.5;
                //     else this.ai.y -= this.ai.speed / 4;
                // }
                // if (this.ai.y < this.ball.y - (this.ai.height / 2)) {
                //     if (this.ball.moveX === DIRECTION.RIGHT) this.ai.y += this.ai.speed / 1.5;
                //     else this.ai.y += this.ai.speed / 4;
                // }
    
                // Handle ai (AI) wall collision
                // if (this.ai.y >= canvas.current.height - this.ai.height) this.ai.y = canvas.current.height - this.ai.height;
                // else if (this.ai.y <= 0) this.ai.y = 0;
    
                // Handle Player-Ball collisions
                if (this.ball.y - this.ball.height <= this.player.y && this.ball.y >= this.player.y - this.player.height) {
                    if (this.ball.x <= this.player.x + this.player.width && this.ball.x + this.ball.width >= this.player.x) {
                        this.ball.y = (this.player.y + this.ball.height);
                        this.ball.moveY = DIRECTION.UP;
    
                    }
                }
    
                // Handle ai-ball collision
                // if (this.ball.x - this.ball.width <= this.ai.x && this.ball.x >= this.ai.x - this.ai.width) {
                //     if (this.ball.y <= this.ai.y + this.ai.height && this.ball.y + this.ball.height >= this.ai.y) {
                //         this.ball.x = (this.ai.x - this.ball.width);
                //         this.ball.moveX = DIRECTION.LEFT;
    
                //     }
                // }
            }
    
            // Handle the end of round transition
            // Check to see if the player won the round.
            // if (this.player.score === rounds[this.round]) {
            //     // Check to see if there are any more rounds/levels left and display the victory screen if
            //     // there are not.
            //     if (!rounds[this.round + 1]) {
            //         this.over = true;
            //         setTimeout(function () { Pong.endGameMenu('Winner!'); }, 1000);
            //     } else {
            //         // If there is another round, reset all the values and increment the round number.
            //         this.color = this._generateRoundColor();
            //         this.player.score = this.ai.score = 0;
            //         this.player.speed += 0.5;
            //         this.ai.speed += 1;
            //         this.ball.speed += 1;
            //         this.round += 1;
    
            //     }
            // }
            // Check to see if the ai/AI has won the round.
            // else if (this.ai.score === rounds[this.round]) {
            //     this.over = true;
            //     setTimeout(function () { Pong.endGameMenu('Game Over!'); }, 1000);
            // }
        },
    
        // Draw the objects to the canvas element
        draw: function () {    
            // Draw the background
            //this.context.fillStyle = this.color;
            //this.context.fillRect(0, 0, canvas.current.width, canvas.current.height - 100);

            //Add borders (level dependent)
            this.context.fillStyle = 'black';
            this.context.fillRect(0,0,50,1100);
            this.context.fillRect(600,0,50,1100);
            this.context.fillRect(0,0,500,50);
    
            // Draw the Player
            this.context.fillStyle = 'black';
            this.context.fillRect(this.player.x, this.player.y, this.player.width, this.player.height);
    
            // Draw the Ball
            if (Pong._turnDelayIsOver.call(this)) this.context.fillRect(this.ball.x, this.ball.y, this.ball.width, this.ball.height)

        },
    
        loop: function () {
            Pong.update();
            Pong.draw();
    
            // If the game is not over, draw the next frame.
            if (!Pong.over) requestAnimationFrame(Pong.loop);
        },
    
        listen: function () {
            document.addEventListener('keydown', function (key) {
                // Handle the 'Press any key to begin' function and start the game.
                // if (Pong.running === false) {
                //     Pong.running = true;
                //     window.requestAnimationFrame(Pong.loop);
                // }

                window.requestAnimationFrame(Pong.loop);

                // Handle key events
                if (key.keyCode === 37 || key.keyCode === 65) Pong.player.move = DIRECTION.LEFT;
                if (key.keyCode === 39 ||  key.keyCode === 68) Pong.player.move = DIRECTION.RIGHT;
            });
            
            // Stop the player from moving when there are no keys being pressed.
            document.addEventListener('keyup', function (key) { Pong.player.move = DIRECTION.IDLE; });
        },
    
        // Reset the ball location, the player turns and set a delay before the next round begins.
        _resetTurn: function(victor, loser) {
            this.ball = Ball.new.call(this, this.ball.speed);
            this.turn = loser;
            this.timer = (new Date()).getTime();
    
            victor.score++;
        },
    
        // Wait for a delay to have passed after each turn.
        _turnDelayIsOver: function() {
            return ((new Date()).getTime() - this.timer >= 1000);
        },
    
        // Select a random color as the background of each level/round.
        _generateRoundColor: function () {
            var newColor = colors[Math.floor(Math.random() * colors.length)];
            if (newColor === this.color) return Pong._generateRoundColor();
            return newColor;
        }
    }

    var Pong = Object.assign({}, Game);
    useEffect(() => { Pong.initialize() }, []);

    return (
        <div className='scape'>
        <canvas className="scape-container" ref={canvas}/>
        </div>
    );
}
