import { Canvas } from '@react-three/fiber';
import { Suspense } from 'react';
import { OrbitControls } from '@react-three/drei';
import { PerspectiveCamera, SpotLight } from 'three';
import MacBookPro from '../assets/objects/MacBookPro';

export default function Home() {

  const CameraHelper = () => {
    const camera = new PerspectiveCamera(60 , 1, 1, 2);
    const light = new SpotLight(0xfffff);
    light.position.set(0,0,0)
    camera.rotateX(18);

    return <group position={[0,0,1]}>
      <cameraHelper args={[camera]}/>
      <pointLightHelper args={[light]}/>
    </group>
  };

  return (
      <Canvas className='canvas' camera={{ position: [0,-2,0], fov: 60 }}>
        {/* <CameraHelper/> */}
        <OrbitControls 
          enablePan={false}
          enableZoom={false} 
          maxAzimuthAngle={Math.PI} 
          maxDistance={5}
          maxPolarAngle={Math.PI/2} 
          minPolarAngle={Math.PI/2}
        />
        <Suspense fallback={null}>
          <MacBookPro/>
        </Suspense>
      </Canvas>
  );
}
