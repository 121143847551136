import React, { useEffect, useRef, useState } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';

export default function Model(props) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF('objects/macbook.glb');
  const { actions } = useAnimations(animations, group);

  useEffect(() => {
    actions.Object_4Action.play();
    
    setInterval(() => {
      group.current.rotateY(0.005);
    }, 10);
  }, []);
  
  return (
    <group ref={group} {...props} dispose={null} position={[0,0,0]}>
      <group position={[-0.2, 0.35, 0]} rotation={[0, 7.5, 0]} scale={[1.75,1.75,1.75]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group position={[0.12, 0.01, 0]} />
          <group name="Object_4">
            <mesh geometry={nodes.Object_0.geometry} material={materials.MacBookPro} />
            <mesh geometry={nodes.Object_0_1.geometry} material={materials.Plastic} />
          </group>
        </group>
      </group>
      <group rotation={[-0.13, -1.19, 1.49]} scale={[0.21, 0.19, 0.19]}>
        <group rotation={[Math.PI / 2, 0, 0]} scale={[0.01, 0.01, 0.01]}>
          <group position={[-250.87, 631.58, -551.82]} rotation={[0, -1.33, -0.85]} scale={[100, 100, 100]} />
        </group>
      </group>
      <mesh
        geometry={nodes.Cube006.geometry}
        material={nodes.Cube006.material}
        position={[0.79, 1.02, -0.1]}
        rotation={[2.23, 1.53, 1.43]}
        scale={[0, 0, 0.07]}
      />
    </group>
  )
}

useGLTF.preload('/macbook.glb')