import './App.scss';
import { useEffect, Suspense, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import Scape from './pages/scape';
import RSVP from './pages/rsvp';

export default function App() {

  return (
    <Router>
      <div className="App" id='main'>
        <Routes>
          <Route path='/home' element={<Home/>}/>
          <Route path='/scape' element={<Scape/>}/>
          <Route path='/' element={<Home/>}/>
        </Routes>
      </div>
    </Router>
  );
}
